import React from "react";
import "./App.css";
import { Row, Col } from "antd";
import {
  WindowsFilled,
  AppleOutlined,
  AndroidOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Button } from "antd";

class Download extends React.Component {
  render() {
    return (
      <div id="bckgDiv">
        <br />
        <br />
        <br />
        <div className="hide" style={{ paddingTop: "5vh" }}>
          <h1
            style={{
              paddingLeft: "42vw",
              justifyContent: "center",
              alignContent: "center",
              fontSize: "3em",
              fontWeight: "bold",
              fontFamily:
                "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
            }}
          >
            Downloads
          </h1>
          <Row
            style={{ paddingLeft: "22vw", alignContent: "center" }}
            gutter={[10]}
          >
            <Col
              xs={{ span: 2, offset: 1 }}
              lg={{ span: 6, offset: 2 }}
              style={{ alignContent: "center" }}
            >
              <div style={{}}>
                <WindowsFilled
                  style={{
                    paddingTop: "10px",
                    fontSize: "1200%",
                    paddingLeft: "2vw",
                  }}
                />
                <div>
                  <br />
                  <br />
                  <a href="https://s3.ap-southeast-2.amazonaws.com/downloads.coras.corpcloud.com.au/windows/Coras.msi">
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      size="large"
                      style={{
                        color: "#001864",
                        backgroundColor: "#FFC20E",
                        borderColor: "#001864",
                        borderRadius: "6px",
                        fontFamily:
                          "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
                      }}
                    >
                      Download Windows Client
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
            <Col
              style={{ alignContent: "center" }}
              xs={{ span: 2, offset: 1 }}
              lg={{ span: 6, offset: 2 }}
            >
              <div style={{}}>
                <AppleOutlined
                  style={{
                    paddingTop: "10px",
                    fontSize: "1200%",
                    paddingLeft: "1.3vw",
                  }}
                />
                <div>
                  <br />
                  <br />
                  <a href="https://s3.ap-southeast-2.amazonaws.com/downloads.coras.corpcloud.com.au/mac/Coras.dmg">
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      size="large"
                      style={{
                        color: "#001864",
                        backgroundColor: "#FFC20E",
                        borderColor: "#001864",
                        borderRadius: "6px",
                        fontFamily:
                          "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
                      }}
                    >
                      Download Mac Client
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="hidemin">
          <div className="hidemin">
            <div
              style={{
                textAlign: "center",
                alignContent: "center",
                paddingTop: "6vh",
              }}
            >
              <h1
                style={{
                  justifyContent: "center",
                  alignContent: "center",
                  fontSize: "3em",
                }}
              >
                Downloads
              </h1>
              <AppleOutlined
                className="hidemin"
                style={{
                  alignContent: "center",
                  fontSize: "1200%",
                }}
              />
              <div style={{ textAlign: "center", alignContent: "center" }}>
                <a href="https://apps.apple.com/au/app/coras-softphone/id1440102495">
                  <Button
                    type="primary"
                    shape="round"
                    icon={<DownloadOutlined />}
                    size="large"
                    style={{ background: "#001f3d", borderColor: "white" }}
                  >
                    Download iPhone App
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              alignContent: "center",
              paddingTop: "8vh",
            }}
          >
            <AndroidOutlined
              style={{ paddingTop: "10px", fontSize: "1200%" }}
            />
            <div
              style={{
                textAlign: "center",
                alignContent: "center",
                paddingBottom: "5vh",
                paddingTop: "3vh",
              }}
            >
              <a href="https://play.google.com/store/apps/details?id=com.corpcloudcoras.voip">
                <Button
                  type="primary"
                  shape="round"
                  icon={<DownloadOutlined />}
                  size="large"
                  style={{ background: "#001f3d", borderColor: "white" }}
                >
                  Download From Play Store
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Download;
