import React from "react";
import { Row } from "antd";
import "./App.css";
class Help extends React.Component {
  render() {
    return (
      <div id="bckgDiv">
        <>
          <Row>
              <div className="helpContainers">
                <h2 style={{ fontSize: "2em", fontWeight: "bold" }}>
                  How to log in to your CorpCloud Coras Softphone
                </h2>
                <p style={{ fontSize: "1em" }}>
                  To log in to your CorpCloud Softphone, your company
                  administrator must have issued you with a CorpCloud Coras
                  username and password. Your username is typically your work
                  email address.
                </p>

                <h2 style={{ fontSize: "1.5em", fontWeight: "bold" }}>
                  How it works
                </h2>
                <p style={{ fontSize: "1em" }}>
                  When you log in to your CorpCloud Softphone as yourself, our
                  system directs the Softphone to authenticate to your
                  organisation’s Coras Server with the correct credentials. This
                  will associate your Coras phone numbers with the Softphone, in
                  addition to any other devices you are using such as hard desk
                  phones.
                </p>
<br />
                <h2 style={{ fontSize: "2em", fontWeight: "bold" }}>
                  Frequently Asked Questions
                </h2>
                <h3 style={{ fontSize: "1.25em" }}>
                  I have forgotten my login username for my Softphone, how do I
                  find it?
                </h3>
                <p style={{ fontSize: "1em", padding: "1vw" }}>
                  Your login username is the same one you use to log in to Coras
                  and is typically your work email address. If you cannot
                  remember it, your company administrator can find it for you.
                </p>
                <h3 style={{ fontSize: "1.25em" }}>
                  I am getting an Authentication Failed error or have forgotten
                  my password
                </h3>
                <p style={{ fontSize: "1em", padding: "1vw" }}>
                  Follow this <a href="/password_reset">link</a> to reset your
                  Coras password. You will need to know your login username.
                </p>
                <h3 style={{ fontSize: "1.25em" }}>
                  I am getting an Internal Server Error error
                </h3>
                <p style={{ fontSize: "1em", padding: "1vw" }}>
                  This is a CorpCloud system fault. Please report it to your
                  company administrator who will refer it on to CorpCloud
                  Support.
                </p>
              </div>
            {/* </Col> */}
            {/* </div> */}
          </Row>
        </>
      </div>
    );
  }
}
export default Help;
