import React from "react";

import { Layout } from "antd";
import { PhoneOutlined, MailOutlined, HomeOutlined } from "@ant-design/icons";
const { Footer } = Layout;
class CCFooter extends React.Component {
  render() {
    return (
      <Footer
        style={{
          textAlign: "center",
          backgroundColor: "#F0F2F5",
          color: "#001864",
          fontFamily:
            "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
           marginBottom: "5px",
        }}
      >
        Coras © {new Date().getFullYear()} Powered by{" "}
        <a
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline", color: "#001864" }}
          href="https://www.corpcloud.com.au/"
        >
          CorpCloud{" "}
        </a>
        <p></p>
        <span>
          <span
            style={{
              textAlign: "left",
              fontSize: "1em",
            }}
          ></span>
          <span>
            <PhoneOutlined />
            {"\u00A0"}
            {"\u00A0"}
            {"\u00A0"}1300 409 552
          </span>
          {/* <br></br> */}
          <span
            style={{
              textAlign: "left",
              fontSize: "1em",
              marginLeft: "25px",
            }}
          >
            <MailOutlined />
            {"\u00A0"}
            {"\u00A0"}
            {"\u00A0"}support@corpcloud.com.au
          </span>{" "}
        </span>
        <br></br>
        <span
          style={{
            textAlign: "left",
            fontSize: "1em",
          }}
        >
          <HomeOutlined />
          {"\u00A0"}
          {"\u00A0"}
          {"\u00A0"}Level 2, 600 Murray St, West Perth WA 6005
        </span>
        <p />
        {/* <a
          // style={{ color: "white" }}
          href="https://www.corpcloud.com.au/terms/"
        >
          Terms{"\u00A0"}
          {"\u00A0"}
          {"\u00A0"}
        </a> */}
        <a
          // style={{ color: "white" }}
          href="https://www.corpcloud.com.au/privacy/"
        >
          Privacy{"\u00A0"}
          {"\u00A0"}
          {"\u00A0"}
        </a>
        <a
          // style={{ color: "white" }}
          href="https://www.corpcloud.com.au/contact"
        >
          Contact Us
        </a>
        <br />
      </Footer>
    );
  }
}

export default CCFooter;
