import React from "react";
import Help from "./Help";
import Download from "./Download";
import Guides from "./Guides";
import { Routes, Route } from "react-router-dom";
import PassReset from "./PassReset";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import CCFooter from "./components/CCFooter";
const { Header, Content } = Layout;

class App extends React.Component {
  constructor(){
    super();              
    this.items = [
      { 
        label: 
          <Link
          className="navbarItem"
          to="/password_reset"
          style={{
            color: "#ffffff",
            fontSize: "10.25pt",
            fontFamily:
              "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
          }}
        >
          Password Reset
        </Link>, 
        key: 'pw_reset',
        style: { marginTop: "16px", marginLeft: "16px" },
      }, // remember to pass the key prop
      { 
        label: 
          <Link
          className="navbarItem"
          to="/help"
          style={{
            color: "#ffffff",
            fontSize: "10.25pt",
            fontFamily:
              "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
          }}
        >
          FAQ
        </Link>, 
        key: 'help',
        style: { marginTop: "16px"},
      }, // remember to pass the key prop
      { 
        label: 
          <Link
          className="navbarItem"
          to="/downloads"
          style={{
            color: "#ffffff",
            fontSize: "10.25pt",
            fontFamily:
              "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
          }}
        >
          Downloads
        </Link>, 
        key: 'downloads',
        style: { marginTop: "16px" },
      }, // remember to pass the key prop
      { 
        label: 
          <Link
          className="navbarItem"
          to="/guides"
          style={{
            color: "#ffffff",
            fontSize: "10.25pt",
            fontFamily:
              "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
          }}
        >
          User Guides
        </Link>, 
        key: 'guides',
        style: { marginTop: "16px" },
      }, // remember to pass the key prop
    ];
 }
  
  render() {
    return (
      <Layout>
        <Header
          style={{
            display: "inline-flex",
            height: "90px",
            paddingLeft: "250",
            background: "#001864",
          }}
        >
          <img
            style={{
              height: "8vh",
              marginBottom: "2px",
              marginTop: "12px",
              paddingLeft: "50px",
            }}
            src="Cloud-Coras-reversed.png"
            alt="CorasLogo"
          />
          <Menu 
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["2"]}
            style={{
              width: "100%",
              height: "90px",
              background: "#001864",
            }}
            items={this.items} 
          />;
        </Header>
        <Content style={{ minHeight: "83vh" }}>
          <Routes>
            <Route relative path="/help" element={<Help />} />
            <Route relative path="/downloads" element={<Download />} />
            <Route relative path="/password_reset" element={<PassReset />} />
            <Route relative path="/guides" element={<Guides />} />
            <Route relative path="/" element={<Help />} />
          </Routes>
        </Content>
        <CCFooter></CCFooter>
      </Layout>
    );
  }
}
export default App;
